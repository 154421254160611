
import Vue from "vue";
import { mapGetters } from "vuex";
import SessionMenu from "@/components/Session/SessionMenu.vue";
import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";
import Certificate from "@/components/Mentoree/Certificate/Certificate.vue";
import SessionsLoading from "@/components/Session/SessionsLoading.vue";

export default Vue.extend({
  name: "MentoringPlanPage",
  components: {
    SessionsLoading,
    Certificate,
    RecommendedMentors,
    SessionMenu
  },
  data() {
    return {
      loading: true,
      data: [] as any,
      page: 1,
      load_more_sessions: false,
      load_more_programs: false,
      load_more_mentorcasts: false,
      tabs: ["All", "Mentorcasts", /*"Sessions",*/ "Programs"],
      isIHave: true,
      currentTab: "All",
      filter: {}
    };
  },
  computed: {
    ...mapGetters({
      moduleSettings: "helper/getModuleSettings",
      certificates: "certificates/getCertificate",
      profile: "profile/getProfile"
    }),
    mentoringSessionStatus(): any {
      return this.moduleSettings["mentoring_session"] == 1;
    },
    mentorcastStatus(): any {
      return this.moduleSettings["mentorcast"] == 1;
    },
    mentoringProgramStatus(): any {
      return this.moduleSettings["mentoring_program"] == 1;
    },
    mentorLibraryStatus(): any {
      return this.moduleSettings["mentor_library"] == 1;
    },
    feedbackEngineStatus(): any {
      return this.moduleSettings["feedback_engine"] == 1;
    },
    mentorCommunityStatus(): any {
      return this.moduleSettings["mentor_community"] == 1;
    },
    sessionCertificateStatus(): any {
      return this.moduleSettings["session_certificate"] == 1;
    }
  },
  mounted(): void {
    this.$store.dispatch("helper/GET_MODULE_SETTINGS");

    if (this.sessionCertificateStatus) {
      this.tabs.push("Sessions");
    }
  },
  created() {
    this.getCertificates(this.page);
  },

  methods: {
    onLoadMore() {
      this.page++;
      this.getCertificates(this.page);
    },
    onTab(tab: any) {
      this.page = 1;
      this.data = [];
      this.currentTab = tab;
      this.getCertificates(this.page);
    },
    onSearch(filter: any) {
      this.isIHave = false;
      this.data = [];
      this.filter = filter;
      this.getCertificates(this.page);
    },
    getCertificates(page: any) {
      this.loading = true;
      this.$store
        .dispatch("certificates/GET_CERTIFICATES", {
          page: page,
          type: this.currentTab,
          filter: this.filter
        })
        .then(data => {
          console.log("cert", data);
          const paginate = data.paginate;
          this.page = page;
          this.load_more_mentorcasts = page < paginate?.mentorcast?.last_page;
          this.load_more_sessions = page < paginate?.session?.last_page;
          this.load_more_programs = page < paginate?.program?.last_page;
          this.data.push(...data.certificates);
          this.loading = false;
        });
    }
  }
});
